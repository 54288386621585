import React from "react";
import UserServiceStats from "../components/Dashboard/ServiceStats";
import HCActionsListTable from "../components/HCActions/ListTable";
import UsersEventsTable from "../components/Users/EventsTable";
import Content from "../containers/Content";

const Dashboard = () => {
  return (
    <Content>
      <UserServiceStats/>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <HCActionsListTable/>
        </div>
        <div className="col-lg-6 col-md-12">
          <UsersEventsTable/>
        </div>
      </div>
    </Content>
  );
};

export default Dashboard;
