import React from 'react'

const TicketUserTypeBadge = (props: {isStaff: boolean}) => {
    if (props.isStaff) {
        return <span className="badge badge-success">Support</span>
    } else {
        return <span className="badge badge-warning">User</span>
    }
}

export default TicketUserTypeBadge

