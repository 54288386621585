import React from "react";
import ServersListTable from "../../components/HCServers/List/ServersList";
import Content from "../../containers/Content";

const ServersList = () => {
  return (
    <Content>
        <ServersListTable />
    </Content>
  );
};

export default ServersList;
