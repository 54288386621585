import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { configuredStore } from "./store";
import Root from "./Root";

const store = configuredStore();

const container = document.getElementById("app") as Element;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>
);

// React 17
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//         <Root />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
