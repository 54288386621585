import React from "react";

const SimplePagination = (props: {
  totalPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) => {
  const { totalPage, currentPage, onPageChange } = props;
  return (
    <ul className="pagination pagination-flat align-self-center">
      {currentPage > 1 && (
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
          >
            ← &nbsp; Prev
          </button>
        </li>
      )}
      {currentPage < totalPage && (
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next &nbsp; →
          </button>
        </li>
      )}
    </ul>
  );
};

export default SimplePagination;
