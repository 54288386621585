import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToSystemJob } from "./mappers";

export const fetchSystemJobs = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.SYSTEM.JOBS.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const jobs: SystemJob[] = data.map((job: any) => jsonToSystemJob(job));
    return jobs;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const runSystemJob = async (id: number) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SYSTEM.JOBS.RUN(id),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return data;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const toggleSystemJob = async (id: number) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.SYSTEM.JOBS.TOGGLE(id),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return data;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export default fetchSystemJobs;
