import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchTicket } from "../../api/tickets";
import TicketDetailsCard from "../../components/Ticketing/Details/TicketDetailsContent";
import TicketDetailsSidebar from "../../components/Ticketing/Details/TicketDetailsSidebar";
import TicketNewReply from "../../components/Ticketing/Details/TicketNewReply";
import TicketReplies from "../../components/Ticketing/Details/TicketReplies";
import Alert from "../../components/Utils/Alert";
import SimpleLoader from "../../components/Utils/SimpleLoader";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";
import ContentCenter from "../../containers/ContentCenter";

const TicketDetails = () => {
  const { slug } = useParams();
  const { data, isError, isLoading } = useQuery(
    APIQueryName.TICKET_DETAILS + slug,
    () => fetchTicket(slug ? slug : ""),
    {
      staleTime: 120000,
      retry: 1,
    }
  );

  if (isLoading) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <SimpleLoader iconSize={5} />
            <p className="mt-3">Loading ticket details ...</p>
          </div>
        </ContentCenter>
      </Content>
    );
  }

  if (isError) {
    return (
      <Content>
        <ContentCenter>
          <div className="text-center mb-5 mt-2">
            <i className={`icon-warning icon-5x text-danger`} />
            <p className="mt-3">
              Failed to load ticket details. Please try again later.
            </p>
          </div>
        </ContentCenter>
      </Content>
    );
  }
  return (
    <Content>
      {data && (
        <div className="row">
          <div className="col-md-8">
            <TicketDetailsCard ticketDetails={data} />
          </div>
          <TicketDetailsSidebar ticketDetails={data} />
        </div>
      )}

      <div className="form-group text-center text-muted content-divider">
        <span className="px-2"> Responses </span>
      </div>

      {data && <TicketReplies replies={data.replies} />}

      <div className="form-group text-center text-muted content-divider">
        <span className="px-2"> New reply </span>
      </div>

      {data && data.closed && (
        <div className="row text-center">
          <Alert text="Ticket is closed" alertType="warning" />
        </div>
      )}
      {data && <TicketNewReply slug={data.slug} />}
    </Content>
  );
};

export default TicketDetails;
