import React from "react";
import {numberWithCommas} from "../../utilities/functions";

const ServerStats = (props: {stats: FullStats}) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-server icon-3x text-orange-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.totalServers}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Servers</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-server icon-3x text-orange-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.baseStats.hcServersCount}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Servers</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="media-body">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.duration)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Server Uptimes (Hours)</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-watch2 icon-3x text-success"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="media-body">
              <h3 className="font-weight-semibold mb-0">{stats.baseStats.usersCount}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Users</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-users4 icon-3x text-blue-400"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerStats;
