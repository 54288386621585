import React from "react";
import HCActionsListTable from "../../components/HCActions/ListTable";
import Content from "../../containers/Content";

const HCActionsList = () => {
  return (
    <Content>
        <HCActionsListTable />
    </Content>
  );
};

export default HCActionsList;
