import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { removeNotification } from "../../features/baseSlice";

type Props = {
  notification: NotificationProps;
  index: number;
};
const Notification = (props: Props) => {
  const { notification, index } = props;
  const dispatch = useDispatch();
  const [fadeTimeout, setFadeTimeout] = useState<NodeJS.Timeout | null>(null);
  const [closeTimeout, setcloseTimeout] = useState<NodeJS.Timeout | null>(null);
  const [closing, setClosing] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const removeHandler = useCallback(() => {
    // closeTimeout && clearTimeout(closeTimeout);
    if (closing) {
      return;
    }
    setClosing(true);
    const timeout = setTimeout(() => {
      switch (notification.notificationScope) {
        case "global":
          dispatch(removeNotification(notification.id));
          break;
        default:
          dispatch(removeNotification(notification.id));
      }
    }, 300);
    setFadeTimeout(timeout);
  }, [notification, dispatch, closing]);

  const getCls = (loaded: boolean, closing: boolean): string => {
    let cls = `ui-pnotify alert alert-${notification.type} alert-styled-right ui-pnotify-fade-normal ui-pnotify-mobile-able ui-pnotify-in ui-pnotify-move`;
    if (loaded) {
      cls += " ui-pnotify-fade-in";
    }

    if (closing) {
      cls = cls.replace(" ui-pnotify-fade-in", "");
      // cls = cls.replace(" ui-pnotify-in", "");
      cls += " ui-pnotify-fade-out";
    }

    return cls;
  };

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
    }
    if (!closeTimeout && !fadeTimeout) {
      const timeout = setTimeout(() => removeHandler(), 10000);
      setcloseTimeout(timeout);
    }

    return () => {
      fadeTimeout && clearTimeout(fadeTimeout);
      closeTimeout && clearTimeout(closeTimeout);
    };
  }, [fadeTimeout, closeTimeout, closing, loaded, removeHandler]);
  return (
    <div
      className={getCls(loaded, closing)}
      style={{
        display: "none",
        width: "400px",
        top: `${index * 100 + 60}px`,
        cursor: "auto",
        zIndex: 100,
      }}
    >
      <div
        className={`brighttheme ui-pnotify-container brighttheme-${notification.type} ui-pnotify-shadow`}
        role="alert"
        style={{ minHeight: "16px" }}
      >
        <div
          className="ui-pnotify-closer"
          tabIndex={0}
          title="Close"
          style={{ cursor: "pointer" }}
          onClick={removeHandler}
        >
          <span className="brighttheme-icon-closer" />
        </div>
        <div className="ui-pnotify-icon">
          <span className="brighttheme-icon-notice" />
        </div>
        <h4 className="ui-pnotify-title">{notification.title}</h4>
        <div className="ui-pnotify-text">{notification.text}</div>
        <div
          className="ui-pnotify-action-bar"
          style={{
            marginTop: "5px",
            clear: "both",
            textAlign: "right",
            display: "none",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Notification;
