import React from "react";
import {numberWithCommas} from "../../utilities/functions";

const ServerResourcesStats = (props: { stats: FullStats }) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-stack3 icon-3x text-indigo"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.ramTotal)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Ram (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-stack3 icon-3x text-success"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.ramActive)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Ram (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-floppy-disks icon-3x text-blue-300"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.diskTotal)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Disk (GB)</span>
            </div>

          </div>
        </div>
      </div>

      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-floppy-disks icon-3x text-blue-600"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.diskActive)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Disk (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-power3 icon-3x text-danger-300"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.coresTotal)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total CPU Cores</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-2 col-xl-2">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-power3 icon-3x text-danger"/>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.coresActive)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active CPU Cores</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerResourcesStats;
