import React from "react";
import {numberWithCommas} from "../../utilities/functions";

const WalletStats = (props: {stats: FullStats}) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-cash3 icon-3x text-success"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.depositsTotal)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Deposits (Toman)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-cash3 icon-3x text-success-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.depositsCount)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Deposits Count</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-wallet icon-3x text-blue-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.totalWalletsBalance)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Wallets Balance (Toman)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-wallet icon-3x text-danger-400"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.totalWalletsDebts)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Wallets Debts</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletStats;
