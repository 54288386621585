import React, { useState } from "react";
import LoginForm from "../components/Auth/LoginForm";
import Content from "../containers/Content";
import ContentWrapper from "../containers/ContentWrapper";
import PageContent from "../containers/PageContent";

const Authentication = (props: any) => {
  const [activeTab, setActiveTab] = useState("login");

  const tabSwitcher = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tab: string
  ) => {
    evt && evt.preventDefault();
    setActiveTab(tab);
  };
  return (
    <PageContent>
      <ContentWrapper>
        <Content cls="d-flex justify-content-center align-items-center">
          <div className="login-form wmin-sm-400">
            <div className="card mb-0">
              <ul className="nav nav-tabs nav-justified bg-dark mb-0">
                <a
                  href="#login"
                  className="nav-item"
                  onClick={(evt) => tabSwitcher(evt, "login")}
                >
                  <span
                    className={
                      activeTab === "login"
                        ? "nav-link border-y-0 border-left-0 active show"
                        : "nav-link border-y-0 border-left-0"
                    }
                    data-toggle="tab"
                  >
                    <h6 className="my-1 text-center"> Login </h6>
                  </span>
                </a>
              </ul>

              <div className="tab-content card-body">
                <div
                  className={
                    activeTab === "login"
                      ? "tab-pane fade active show"
                      : "tab-pane fade"
                  }
                >
                  <div className="text-center mb-3">
                    <i className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" />
                    <br />
                    <h5 className="mb-0"> Login </h5>
                  </div>

                  <LoginForm {...props} />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </ContentWrapper>
    </PageContent>
  );
};

export default Authentication;
