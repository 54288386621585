import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes";
import { numberWithCommas } from "../../utilities/functions";

const yesIcon = <i className="icon-checkmark2 position-static text-success" />;

const noIcon = <i className="icon-cross3 position-static text-danger" />;

const UserListTR = (props: { user: User }) => {
  const { user } = props;
  const navigate = useNavigate();

  return (
    <tr key={"user" + user.id} style={{ cursor: "pointer" }} onClick={() => navigate(`${routes.USERS}/${user.id}/info`)}>
      <td>{user.id}</td>
      <td>{`${user.firstName} ${user.lastName}`}</td>
      <td>{!!user.phoneVerifiedAt ? yesIcon : noIcon}</td>
      <td>{user.email}</td>
      <td>{!!user.emailVerifiedAt ? yesIcon : noIcon}</td>
      <td>{numberWithCommas(user.balance)}</td>
    </tr>
  );
};

export default UserListTR;
