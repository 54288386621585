import React from "react";
import { Navigate } from "react-router-dom";
import routes from "../../../constants/routes";
import { useUserAddCredit } from "../../../mutations/users";

const UserAddCreditForm = (props: {user: User}) => {
  const {user} = props

  const { mutate, isSuccess, isLoading } = useUserAddCredit();

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const description = evt.currentTarget.description.value;
    const amount = parseInt(evt.currentTarget.amount.value);
    mutate({
      description,
      amount,
      user_id: user.id,
    });
  };

  if (isSuccess) {
    return <Navigate to={routes.USERS} />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <form onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="form-group col-12">
                  <label>Amount:</label>
                  <input
                    name="amount"
                    id="amount"
                    type="number"
                    className="form-control touchspin-empty text-center"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  id="description"
                  rows={3}
                  cols={3}
                  className="form-control"
                  placeholder="Description"
                />
              </div>

              <div className="text-left">
                {!isLoading && (
                  <button type="submit" className="btn btn-primary">
                    Add
                  </button>
                )}
                {isLoading && (
                  <button disabled className="btn btn-primary">
                    <i className="icon-spinner6 spinner mr-2" /> Adding ...
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAddCreditForm;
