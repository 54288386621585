import React from "react";
import {numberWithCommas} from "../../utilities/functions";

const VolumeStats = (props: {stats: FullStats}) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-add icon-3x text-slate-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.baseStats.hcVolumesTotalCount}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Volumes</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-add icon-3x text-slate-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcVolumesTotalSize)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Volumes Size (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-upload icon-3x text-orange-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcVolumesCount)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Volumes</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-upload icon-3x text-orange-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcVolumesSize)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Volumes Size</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-bag icon-3x text-danger"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolumeStats;
