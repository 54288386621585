import React from "react";
import ExchangeRateCreateFrom from "../../components/ExchangeRates/Create";
import Content from "../../containers/Content";

const ExchangeRateCreate = () => {
  return (
    <Content>
        <ExchangeRateCreateFrom />
    </Content>
  );
};

export default ExchangeRateCreate;
