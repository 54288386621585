import React from "react";
import { useQuery } from "react-query";
import { fetchExchangeRates } from "../../api/exchnageRates";
import { APIQueryName } from "../../constants/queries";
import LoadDataFailed from "../Utils/LoadDataFailed";
import LoadingData from "../Utils/LoadingData";
import ExchangeRateListTR from "./JobTR";

// TODO: fix loading exchange rates after creating a new one
const ExchangeRateListTable = () => {
  const { data, isError, isLoading } = useQuery(
    APIQueryName.EXCHANGE_RATES,
    fetchExchangeRates,
    {
      staleTime: 60000,
    }
  );

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>From</th>
              <th>To</th>
              <th>Rate</th>
              <th>Default</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((rate) => (
              <ExchangeRateListTR rate={rate} key={"rate" + rate.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ExchangeRateListTable;
