import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchUsers } from "../../api/users";
import LoadDataFailed from "../Utils/LoadDataFailed";
import LoadingData from "../Utils/LoadingData";
import Pagination from "../Utils/Pagination";
import TableEntries from "../Utils/TableEntiries";
import UserListTR from "./UserTR";

const UserListTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<any>(null);

  const { data, isError, isLoading } = useQuery(
    ["users", page, pageSize, search],
    () => fetchUsers(page, pageSize, search),
    { keepPreviousData: true, staleTime: Infinity }
  );

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value;
    searchTimeout && clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        setSearch(q);
      }, 500)
    );
  };

  useEffect(() => {
    return () => clearTimeout(searchTimeout);
  }, [searchTimeout]);

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Users</h6>
      </div>
      <div className="dataTables_wrapper no-footer">
        <div className="datatable-header">
          <div className="dataTables_filter">
            <label>
              <span>Filter:</span>{" "}
              <input
                type="search"
                className=""
                placeholder="Type to filter..."
                onChange={searchHandler}
              />
            </label>
          </div>
          <div className="dataTables_length">
            <label>
              <span>Show:</span>{" "}
              <select
                name="DataTables_Table_0_length"
                className="custom-select"
                onChange={(evt) => setPageSize(parseInt(evt.target.value))}
                defaultValue={pageSize}
              >
                <option key={"pageSize10"} value="10">
                  10
                </option>
                <option key={"pageSize25"} value="25">
                  25
                </option>
                <option key={"pageSize50"} value="50">
                  50
                </option>
                <option key={"pageSize100"} value="100">
                  100
                </option>
              </select>
            </label>
          </div>
        </div>
        <div className="datatable-scroll">
          <table className="table datatable-basic dataTable no-footer">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Phone Verified</th>
                <th>Email</th>
                <th>Email Verified</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((user: User) => (
                <UserListTR user={user} key={"user" + user.id} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="datatable-footer">
          <TableEntries
            page={page}
            pageSize={pageSize}
            total={data ? data.total : 0}
          />
          <div className="dataTables_paginate paging_simple_numbers">
            <Pagination
              totalPage={data ? data.lastPage : 1}
              currentPage={page}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListTable;
