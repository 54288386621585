const routes = {
  HOME: "/",
  USER_PROFILE: "/user",
  FORGET_PASSWORD: "/auth/reset",
  USER_PHONE_VERIFICATION: "/user/phone/verification",
  USER_EMAIL_VERIFICATION: "/auth/email/verification",
  USER_PASSWORD_CHANGE: "/user/password/change",
  SSH_KEYS: "/sshkeys",
  AUTHENTICATION: "/auth",
  TICKET_CREATE: "/ticketing/new",
  BILLING: "/billing",
  BILLING_LOGS: "/billing/logs",
  BILLING_ADD_FUND: "/billing/fund",
  BILLING_ADD_FUND_VERIFY: "/billing/fund/verify/",
  SERVERS: "/servers",
  CREATE_SERVER: "/servers/create",
  NEW_SERVER_INFO: "/servers/create/info",
  SERVER_DETAILS: "/servers/",
  SNAPSHOTS: "/servers/snapshots",
  BACKUPS: "/servers/backup/",
  VOLUMES: "/servers/volumes/",
  WEB_MONITORING_BASE: "/monitoring",
  WEB_MONITORING: "/monitoring/web",
  WEB_MONITORING_REPORT: "/monitoring/web/",
  WEB_MONITORING_ALERT_DETAILS: "/monitoring/alert/web/",
  WEB_MONITORING_CREATE: "/monitoring/web/new",
  WEB_MONITORING_ALERT_CREATE: "/monitoring/alert/new",
  WEB_MONITORING_ALERT_LIST: "/monitoring/alert",

  TICKETS: "/tickets",
  TICKET_DETAILS: "/tickets/",
  USERS: "/users",
  HC_SERVERS: "/hc/servers",
  HC_IMAGES: "/hc/images",
  HC_ACTIONS: "/hc/actions",
  SYSTEM: "/system",
  JOBS: "/system/jobs",
  EXCHANGE_RATES: "/exchange-rates",
  EXCHANGE_RATE_CREATE: "/exchange-rates/create",
  STATS: "/stats",
  FULL_STATS: "/stats/full",
};

export default routes;
