import React from "react";
import { datetimeToString } from "../../utilities/functions";

const ImageListTR = (props: { image: HCImage }) => {
  const { image } = props;

  return (
    <tr key={"hcimage" + image.id}>
      <td>{image.id}</td>
      <td>{image.description}</td>
      <td>{image.type}</td>
      <td>{image.status}</td>
      <td>{image.imageSize.toFixed(2)}</td>
      <td>{image.diskSize}</td>
      <td>{image.user ? image.user.email : "-"}</td>
      <td>{datetimeToString(image.createdAt)}</td>
    </tr>
  );
};

export default ImageListTR;
