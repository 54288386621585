import React from "react";

const TicketDetailsCard = (props: { ticketDetails: Ticket }) => {
  const { ticketDetails } = props;
  return (
    <div className="card">
      <div className="card-header header-elements-inline mt-2">
        <h2
          className="card-title"
          style={{ direction: "rtl", textAlign: "right" }}
        >
          {ticketDetails ? ticketDetails.title : ""}
        </h2>
      </div>

      <div
        className="card-body mt-2 mb-2"
        style={{ whiteSpace: "pre-line", direction: "rtl", textAlign: "right" }}
      >
        <p>{ticketDetails ? ticketDetails.text : ""}</p>
      </div>

      <div className="card-footer bg-light d-flex justify-content-between align-items-center">
        {ticketDetails ? (
          <span>
            {`${ticketDetails.user.firstName} ${ticketDetails.user.lastName} (${ticketDetails.user.email})`}
            {" - "}
            <span className="font-weight-semibold">
              {!!ticketDetails.admin ? "Support" : "User"}
            </span>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TicketDetailsCard;
