import React from "react";
import {numberWithCommas} from "../../../utilities/functions";

const UserServerStats = (props: { stats: UserFullHCServersStats }) => {
  const {stats} = props;

  return (

    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-server icon-3x text-orange-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.totalServers}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-server icon-3x text-orange-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.activeServers}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="media-body">
              <h3 className="font-weight-semibold mb-0">
                {numberWithCommas(stats.totalDuration)} - {numberWithCommas(stats.totalDuration / 24)}
              </h3>
              <span className="text-uppercase font-size-sm text-muted">Uptimes (Hours/Days)</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-watch2 icon-3x text-success-300"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="media-body">
              <h3 className="font-weight-semibold mb-0">
                {stats.totalServers < 1 ? "0" :
                  numberWithCommas(stats.totalDuration / stats.totalServers)}
                {" - "}
                {stats.totalServers < 1 ? "0" :
                  numberWithCommas(stats.totalDuration / 24 / stats.totalServers)}
              </h3>
              <span className="text-uppercase font-size-sm text-muted">Avg Uptime (Hours/Days)</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-watch2 icon-3x text-success"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="media-body">
              <h3 className="font-weight-semibold mb-0">
                {stats.todayCreates}
              </h3>
              <span className="text-uppercase font-size-sm text-muted">Today Creates</span>
            </div>

            <div className="ml-3 align-self-center">
              <i className="icon-plus2 icon-3x text-blue-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserServerStats;
