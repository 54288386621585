import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToPaginatedHCActions } from "./mappers";

export const fetchHCActions = async (page: number, pageSize: number) => {
  try {
  const { data } = await axios.get(apiRoutes.V1.HC.ACTIONS.LIST(page, pageSize), {
    headers: getDefaultHeaders(getTokenString()),
  });
  return jsonToPaginatedHCActions(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export default fetchHCActions
