import React from "react";
import UserAddCreditForm from "./AddCreditForm";
import UserInfo from "./Info";
import UserFullStats from "./FullStats";

const UserDetailsContent = (props: { user: User, activeTab: string }) => {
  const {user, activeTab} = props;

  switch (activeTab) {
    case "info":
      return <UserInfo user={user}/>;
    case "add-credit":
      return <UserAddCreditForm user={user}/>;
    case "full-stats":
      return <UserFullStats user={user}/>;
    default:
      return <div></div>;
  }
};

export default UserDetailsContent;
