import React from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import { isAuthenticated } from "./api/auth";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import routes from "./constants/routes";
import ContentWrapper from "./containers/ContentWrapper";
import Notifications from "./containers/Notifications";
import PageContent from "./containers/PageContent";
import Authentication from "./pages/Authentication";
import Dashboard from "./pages/Dashboard";
import { QueryClient, QueryClientProvider } from "react-query";

import "./static/css/icomoon/styles.min.css";
// import "./static/css/bootstrap.min.css";
// import "./static/css/bootstrap_limitless.min.css";
// import "./static/css/layout.min.css";
// import "./static/css/components.min.css";
import "./static/css/colors.min.css";
// import "./static/css/style.min.css";
// import "./static/css/styles.min.css";
import "./static/css/all.min.css";
import UserList from "./pages/Users/List";
import JobList from "./pages/SystemJobs/Jobs";
import ImageList from "./pages/HCImages/List";
import ExchangeRateList from "./pages/ExchnageRates/List";
import ExchangeRateCreate from "./pages/ExchnageRates/Create";
import TicketList from "./pages/Ticketing/List";
import TicketDetails from "./pages/Ticketing/Details";
import HCActionsList from "./pages/HCActions/List";
import ServersList from "./pages/HCServers/List";
import UserDetails from "./pages/Users/Details";
import FullStats from "./pages/FullStats";

const queryClient = new QueryClient();

const AuthLayout = (props: any) => {
  return (
    <Routes>
      <Route path={"/"} element={<Authentication {...props} />} />
    </Routes>
  );
};

function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();

  if (!isAuthenticated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={routes.AUTHENTICATION} state={{ from: location }} />;
  }

  return children;
}

const DefaultLayout = (props: any) => {
  return (
    <RequireAuth>
      <>
        <Navbar />
        <PageContent>
          <Sidebar />
          <ContentWrapper>
            <Outlet />
          </ContentWrapper>
        </PageContent>
        <Notifications />
        {/* <ServerDetailsActionsHandler />
      <VolumeActionsHandler /> */}
      </>
    </RequireAuth>
  );
};

function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={"/auth"} element={<AuthLayout />} />
          <Route element={<DefaultLayout />}>
            <Route
              path={`${routes.TICKETS}/:slug`}
              element={<TicketDetails />}
            />
            <Route path={routes.FULL_STATS} element={<FullStats />} />
            <Route path={routes.TICKETS} element={<TicketList />} />
            <Route path={routes.USERS} element={<UserList />} />
            <Route path={`${routes.USERS}/:userId/`} element={<UserDetails />} />
            <Route path={`${routes.USERS}/:userId/:tab`} element={<UserDetails />} />
            <Route path={routes.HC_SERVERS} element={<ServersList />} />
            <Route path={routes.HC_IMAGES} element={<ImageList />} />
            <Route path={routes.HC_ACTIONS} element={<HCActionsList />} />
            <Route path={routes.JOBS} element={<JobList />} />
            <Route
              path={routes.EXCHANGE_RATES}
              element={<ExchangeRateList />}
            />
            <Route
              path={routes.EXCHANGE_RATE_CREATE}
              element={<ExchangeRateCreate />}
            />
            <Route path={"/"} element={<Dashboard />} />
          </Route>
          {/* <Route exact path={'/reset/:key'} render={({ match }) => <ResetPassword text s={this.state.texts} match={match} />} /> */}
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default Root;
