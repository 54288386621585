import React from "react";
import { useUserVerifyEmail } from "../../../mutations/users";

const yesIcon = <i className="icon-checkmark2 icon-2x text-success border-success-100 border-3 rounded-pill p-3 mb-3 mt-1" />;

const noIcon = <i className="icon-cross3 icon-2x text-danger border-danger-100 border-3 rounded-pill p-3 mb-3 mt-1" />;

const UserEmailVerification = (props: {user: User}) => {
  const { user } = props;

  const { mutate, isLoading } = useUserVerifyEmail();
  return (
    <div className="card">
      <div className="card-body text-center">
        {user.emailVerifiedAt ? yesIcon : noIcon}
        <h5 className="card-title">Email Verified</h5>
        <button
          className="btn btn-success"
          disabled={!!user.emailVerifiedAt || isLoading}
          onClick={() => mutate(user.id)}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default UserEmailVerification;
