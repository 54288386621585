import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../constants/routes";

const ServersListTableBody = (props: { servers?: HCServer[] }) => {
  const { servers } = props;
  const navigate = useNavigate();

  return (
    <tbody>
      {servers?.map((server) => (
        <tr
          className="text-center bg-light"
          key={"hcServer" + server.slug}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(routes.SERVER_DETAILS + server.slug)}
        >
          <td className="text-center">
            <span className="font-weight-bold text-default text-center">
              {server.name} ({server.serverType.name.toUpperCase()})
            </span>
            <br />
            <span className="text-default text-center">
              {server.location.country} | {server.serverType.cores}
              vCPU | {server.serverType.memory}GB Ram | {server.serverType.disk}
              GB Storage
            </span>
          </td>
          <td className="text-center">{`${server.user.id} - ${server.user.email}`}</td>
          <td className="text-center">{server.activeTime} Hours</td>
          <td className="text-center">{server.ipv4}</td>
          <td className="text-center">{server.status}</td>
        </tr>
      ))}
      {servers && servers.length < 1 && (
        <tr>
          <td colSpan={5} className="text-center">
            There are no servers!
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default ServersListTableBody;
