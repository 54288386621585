import React from "react";

const Pagination = (props: {
  totalPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}) => {
  const { totalPage, currentPage, onPageChange } = props;
  return (
    <ul className="pagination pagination-flat align-self-center">
      {currentPage > 1 && (
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
          >
            ← &nbsp; Prev
          </button>
        </li>
      )}
      {Array(totalPage)
        .fill(0)
        .map((_, i) => (
          <li
            className={`page-item ${i + 1 === currentPage ? "active" : ""}`}
            key={"pagination" + i}
          >
            <button className="page-link" onClick={() => onPageChange(i + 1)}>
              {i + 1}
            </button>
          </li>
        ))}
      {currentPage < totalPage && (
        <li className="page-item">
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next &nbsp; →
          </button>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
