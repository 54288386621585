import React from "react";
import { datetimeToString } from "../../../utilities/functions";
import TicketUserTypeBadge from "../TicketUserTypeBadge";

const TicketReply = (props: { reply: TicketReply }) => {
  const { reply } = props;

  return (
    <div
      className={`card border-left-3 border-left-${
        !!reply.admin ? "success" : "danger"
      } rounded-left-0`}
      key={"ticketReply" + reply.createdAt}
    >
      <div className="card-body">
        <div className="d-sm-flex align-item-sm-center flex-sm-nowrap">
            <p
              className="mb-3 mt-3"
              style={{
                whiteSpace: "pre-line",
                direction: "rtl",
                textAlign: "right",
              }}
            >
              {reply.text}
            </p>

          <ul className="list list-unstyled mb-0 mt-3 mt-sm-0 ml-auto">
            <li>
              <span className="text-muted">
                {datetimeToString(reply.createdAt)}
              </span>
            </li>
            <li className="text-center">
              <TicketUserTypeBadge isStaff={!!reply.admin} />
            </li>
          </ul>
        </div>
      </div>

      <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center">
        <span>
          <span className="font-weight-semibold">
            {!!reply.admin ? "Support" : "User"}
          </span>
        </span>
      </div>
    </div>
  );
};

export default TicketReply;
