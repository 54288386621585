import React from "react";

type Props = {
  on: boolean;
  label?: string;
  disabled?: boolean;
  onChange: CallableFunction;
};

export default function SimpleRadio(props: Props) {
  const { on, onChange, label, disabled } = props;
  return (
    <div className="custom-control custom-switch" onClick={() => onChange()}>
      <input
        type="checkbox"
        className="custom-control-input"
        checked={on}
        onChange={() => onChange()}
        disabled={disabled}
      />
      <label className="custom-control-label">{label || ""}</label>
    </div>
  );
}

SimpleRadio.defaultProps = {
  label: null,
  disabled: false,
};
