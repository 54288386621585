import React from "react";
import { useQuery } from "react-query";
import { fetchHCImages } from "../../api/hcImages";
import { APIQueryName } from "../../constants/queries";
import LoadDataFailed from "../Utils/LoadDataFailed";
import LoadingData from "../Utils/LoadingData";
import ImageListTR from "./ImageTR";

const ImageListTable = () => {
  const { data, isError, isLoading } = useQuery(
    APIQueryName.HC_IMAGES,
    fetchHCImages,
    { staleTime: 20000, retry: 1 }
  );

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Users</h6>
      </div>
      <div className="dataTables_wrapper no-footer">
        <div className="datatable-header">
          <div className="dataTables_filter">
            <label>
              <span>Filter:</span>{" "}
              <input
                type="search"
                className=""
                placeholder="Type to filter..."
              />
            </label>
          </div>
        </div>
        <div className="datatable-scroll">
          <table className="table datatable-basic dataTable no-footer">
            <thead>
              <tr>
                <th>ID</th>
                <th>Description</th>
                <th>Type</th>
                <th>Status</th>
                <th>Image Size</th>
                <th>Disk Size</th>
                <th>User</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((image: HCImage) => (
                <ImageListTR image={image} key={"hcimage" + image.id} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ImageListTable;
