import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useCreateExchangeRate } from "../../api/exchnageRates";
import routes from "../../constants/routes";
import SimpleRadio from "../Utils/SimpleRadio";
import CurrencySelect from "./CurrencySelect";

const ExchangeRateCreateFrom = () => {
  const [defaultCheck, setDefaultCheck] = useState(false);

  const { mutate, isSuccess, isLoading } = useCreateExchangeRate();

  const formSubmitHandler = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const name = evt.currentTarget.rateName.value;
    const description = evt.currentTarget.description.value;
    const rate = parseFloat(evt.currentTarget.rate.value);
    const from = evt.currentTarget.from.value;
    const to = evt.currentTarget.to.value;
    if (from < 1 || to < 1) {
      return;
    }
    mutate({
      name,
      description,
      from,
      to,
      rate,
      default: defaultCheck,
    });
  };

  if (isSuccess) {
    return <Navigate to={routes.EXCHANGE_RATES} />;
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <form onSubmit={formSubmitHandler}>
              <div className="row">
                <div className="form-group col-10">
                  <input
                    name="rateName"
                    id="rateName"
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    maxLength={64}
                    required
                  />
                </div>
                <div className="form-group col-2 text-center">
                  <SimpleRadio
                    label="Default"
                    on={defaultCheck}
                    onChange={() => setDefaultCheck(!defaultCheck)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-4">
                  <label>From:</label>
                  <CurrencySelect fieldName={"from"} />
                </div>
                <div className="form-group col-4">
                  <label>To:</label>
                  <CurrencySelect fieldName={"to"} />
                </div>
                <div className="form-group col-4">
                  <label>Rate:</label>
                  <input
                    name="rate"
                    id="rate"
                    type="number"
                    className="form-control touchspin-empty text-center"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  id="description"
                  rows={3}
                  cols={3}
                  className="form-control"
                  placeholder="Description"
                />
              </div>

              <div className="text-left">
                {!isLoading && (
                  <button type="submit" className="btn btn-primary">
                    Create
                  </button>
                )}
                {isLoading && (
                  <button disabled className="btn btn-primary">
                    <i className="icon-spinner6 spinner mr-2" /> Creating ...
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRateCreateFrom;
