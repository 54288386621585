import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import fetchStats from "../../api/stats";
import { APIQueryName } from "../../constants/queries";
import routes from "../../constants/routes";
import { numberWithCommas } from "../../utilities/functions";
import SimpleLoader from "../Utils/SimpleLoader";

const UserServiceStats = () => {
  const { data, isLoading } = useQuery(APIQueryName.SERVICE_STATS, fetchStats, {
    staleTime: 120000,
    retry: 1,
  });
  return (
    <div className="card card-body mt-3">
      <p className="text-center font-weight-semibold">Service Stats</p>
      <div className="row justify-content-center">
        <div className="col-md-2 p-2 text-center">
          <p className="text-center">
            <Link to={routes.USERS}>
              <i className="icon-users4 icon-2x d-inline-block text-blue-400" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {data.usersCount}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">Users</span>
        </div>

        <div className="col-md-2 p-2 text-center">
          <p className="text-center">
            <Link to={routes.VOLUMES}>
              <i className="icon-server icon-2x d-inline-block text-orange-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {data.hcServersCount}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">HC Servers</span>
        </div>

        <div className="col-md-2 p-2 text-center">
          <p className="text-center">
            <Link to={routes.SNAPSHOTS}>
              <i className="icon-database-add icon-2x d-inline-block text-primary-600" />
            </Link>
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${data.hcVolumesCount} - ${data.hcVolumesSize} GB`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">HC Volumes</span>
        </div>

        <div className="col-md-2 p-2 text-center">
          <p className="text-center">
            <i className="icon-camera icon-2x d-inline-block text-slate-600" />
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${data.hcSnapshotsCount} - ${data.hcSnapshotsSize.toFixed(2)} GB`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">HC Snapshots</span>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <i className="icon-wallet icon-2x d-inline-block text-pink" />
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${numberWithCommas(data.totalWalletsBalance)}`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">Wallets Balance</span>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 p-2 text-center">
          <p className="text-center">
            <i className="icon-wallet icon-2x d-inline-block text-slate" />
          </p>
          {isLoading && <SimpleLoader />}
          {data && (
            <h5 className="font-weight-semibold mb-0 text-center">
              {`${numberWithCommas(data.totalWalletsDebts)}`}
            </h5>
          )}
          <span className="text-muted ml-2 font-size-sm">Wallets Debt</span>
        </div>
      </div>
    </div>
  );
};

export default UserServiceStats;
