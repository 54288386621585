import React from "react";
import { datetimeToString } from "../../utilities/functions";

const EventTR = (props: { event: UserEvent }) => {
  const { event } = props;

  return (
    <tr key={"userEvent" + event.id}>
      <td>{event.type.replaceAll("_", " ")}</td>
      <td>{event.user.email}</td>
      <td>{datetimeToString(event.createdAt)}</td>
    </tr>
  );
};

export default EventTR;
