import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { fetchUser } from "../../api/users";
import UserDetailsInfoBar from "../../components/Users/Details/InfoBar";
import UserDetailsTabs from "../../components/Users/Details/Tabs";
import { APIQueryName } from "../../constants/queries";
import Content from "../../containers/Content";
import UserDetailsContent from "../../components/Users/Details/DetailsContent";

const UserDetails = () => {
  const { userId, tab } = useParams();
  const { data, isLoading } = useQuery(
    APIQueryName.USER + userId,
    () => fetchUser(parseInt(userId || "0")),
    { keepPreviousData: true, staleTime: Infinity }
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!data) {
    return <div>Not found</div>;
  }
  return (
    <Content>
      <div className="row">
        <div className="col-md-12">
          <UserDetailsInfoBar user={data} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="nav-tabs-responsive mb-3">
            <UserDetailsTabs activeTab={tab || "info"} userId={data.id} />
          </div>
          <UserDetailsContent activeTab={tab || "info"} user={data} />
        </div>
      </div>
    </Content>
  );
};

export default UserDetails;
