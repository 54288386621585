import React from "react";
import ExchangeRateListTable from "../../components/ExchangeRates/ListTable";
import Content from "../../containers/Content";

const ExchangeRateList = () => {
  return (
    <Content>
        <ExchangeRateListTable />
    </Content>
  );
};

export default ExchangeRateList;
