// Server
export const jsonToServerType = (data: any) => {
  let prices: { [key: string]: ServerTypePrice } = {};
  Object.keys(data.prices).forEach((pr: string) => {
    prices[pr] = {
      hourly: data.prices[pr].hourly,
      hourlyEuro: data.prices[pr].hourly_euro,
      monthly: data.prices[pr].monthly,
      monthlyEuro: data.prices[pr].monthly_euro,
    };
  });

  const serverType: ServerType = {
    id: data.id,
    name: data.name,
    description: data.description,
    cores: data.cores,
    memory: data.memory,
    disk: data.disk,
    storageType: data.storage_type,
    cpuType: data.cpu_type,
    availableLocations: data.available_locations,
    prices: prices,
  };
  return serverType;
};

export const jsonToServerLocation = (data: any) => {
  const serverLocation: ServerLocation = {
    id: data.id,
    name: data.name,
    description: data.description,
    country: data.country,
    city: data.city,
    networkZone: data.network_zone,
  };
  return serverLocation;
};

export const jsonToDataCenter = (data: any) => {
  const dc: DataCenter = {
    id: data.id,
    name: data.name,
    description: data.description,
  };

  return dc;
};

export const jsonToServerSummary = (data: any) => {
  const server: ServerSummary = {
    slug: data.slug,
    locked: data.locked,
    creditLocked: data.credit_locked,
    name: data.name,
    status: data.status,
    description: data.description,
  };
  return server;
};

export const jsonToServerImageData = (data: any) => {
  const imageData: ServerImageData = {
    id: data.id,
    name: data.name,
    description: data.description,
    osFlavor: data.os_flavor,
    osVersion: data.os_version,
    diskSize: data.disk_size,
    imageSize: data.image_size,
    status: data.status,
    type: data.type,
    created: data.created,
    boundTo: data.bound_to ? jsonToServerSummary(data.bound_to) : null,
    createdFrom: data.created_from
      ? jsonToServerSummary(data.created_from)
      : null,
  };
  return imageData;
};

export const jsonToServer = (data: any) => {
  const server: UserServerData = {
    slug: data.slug,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    locked: data.locked,
    creditLock: data.credit_lock,
    created: data.created,
    activeTime: data.active_time,
    backupEnabled: data.backup_enabled,
    diskSize: data.disk_size,
    rescueMode: data.rescue_mode,
    serverType: jsonToServerType(data.server_type),
    serverLocation: jsonToServerLocation(data.server_location),
    dataCenter: jsonToDataCenter(data.data_center),
    image: jsonToServerImageData(data.image),
  };

  return server;
};

export const jsonToServerDetails = (data: any) => {
  const server: UserServerDetails = {
    slug: data.slug,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    locked: data.locked,
    creditLock: data.credit_lock,
    created: data.created,
    activeTime: data.active_time,
    backupEnabled: data.backup_enabled,
    diskSize: data.disk_size,
    rescueMode: data.rescue_mode,
    serverType: jsonToServerType(data.server_type),
    serverLocation: jsonToServerLocation(data.server_location),
    dataCenter: jsonToDataCenter(data.data_center),
    image: jsonToServerImageData(data.image),
    ipv6: data.ipv6,
    includedTraffic: data.included_traffic,
    outgoingTraffic: data.outgoing_traffic,
    incomingTraffic: data.incoming_traffic,
    billedAmount: data.billed,
    snapshots: data.snapshots
      ? data.snapshots.map((snapshot: any) => {
          return jsonToServerImageData(snapshot);
        })
      : [],
    backups: data.backups
      ? data.backups.map((backup: any) => {
          return jsonToServerImageData(backup);
        })
      : [],
    iso: data.iso ? jsonToISO(data.iso) : null,
    volumes: data.volumes
      ? data.volumes.map((volume: any) => {
          return jsonToVolumeDetails(volume);
        })
      : [],
  };
  return server;
};

export const jsonToISO = (data: any) => {
  const iso: ISO = {
    id: data.id,
    name: data.name,
    description: data.description,
  };
  return iso;
};

// Volume

export const jsonToVolumeSummary = (data: any) => {
  const volume = {
    id: data.id,
    name: data.name,
    status: data.status,
    description: data.description,
    created: data.created,
    format: data.format,
    size: data.size,
  };
  return volume;
};

export const jsonToVolumeDetails = (data: any) => {
  const volume: VolumeDetails = {
    id: data.id,
    name: data.name,
    status: data.status,
    description: data.description,
    created: data.created,
    format: data.format,
    size: data.size,
    hetznerId: data.hetzner_id,
    server: data.server ? jsonToServerSummary(data.server) : null,
    location: jsonToServerLocation(data.location),
    linuxDevice: data.linux_device,
  };
  return volume;
};

// Billing

export const jsonToBillingLogs = (data: any) => {
  const log: BillingLog = {
    id: data.pk,
    amount: data.amount,
    logType: data.log_type.name,
    fromCurrency: data.from_currency.name,
    currency: data.currency.name,
    exchangeRate: data.exchange_rate_value,
    note: data.note,
    fromDate: data.from_datetime,
    toDate: data.to_datetime,
    created: data.created,
    server: data.server ? jsonToServerSummary(data.server) : null,
    snapshot: data.snapshot ? jsonToServerImageData(data.snapshot) : null,
    volume: data.volume ? jsonToVolumeSummary(data.volume) : null,
  };
  return log;
};

export const jsonToTicketStats = (data: any) => {
  const stats: TicketStats = {
    total: data.total,
    answered: data.answered,
    waiting: data.waiting,
  };
  return stats;
};

export const jsonToWallet = (data: any) => {
  const wallet: Wallet = {
    balance: data.balance,
    monthUsage: data.month_usage,
    totalDeposits: data.total_deposits,
  };
  return wallet;
};

export const jsonToPaymentConfirmationResponse = (data: any) => {
  const res: PaymentConfirmationResponse = {
    amount: data.amount,
    key: data.key,
    success: data.success,
    old: data.old,
  };
  return res;
};

////////////////////////////////////////////////////////////////////////

export const jsonToUser = (data: any) => {
  const user: User = {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    phone: data.phone,
    phoneVerifiedAt: data.phone_verified_at
      ? new Date(data.phone_verified_at)
      : null,
    email: data.email,
    emailVerifiedAt: data.email_verified_at
      ? new Date(data.email_verified_at)
      : null,
    postalCode: data.postal_code,
    address: data.address,
    balance: data.balance,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    deletedAt: data.deleted_at ? new Date(data.deleted_at) : null,
  };
  return user;
};

export const jsonToUserListResponse = (data: any) => {
  const users: User[] = data.data.map((u: any) => jsonToUser(u));
  const res: UserListResponse = {
    total: data.total,
    count: data.count,
    page: data.page,
    pageSize: data.page_size,
    lastPage: data.last_page,
    data: users,
  };
  return res;
};

export const jsonToSystemJob = (data: any) => {
  const job: SystemJob = {
    id: data.id,
    name: data.name,
    description: data.description,
    timeout: data.timeout,
    interval: data.interval,
    enabled: data.enabled,
    lastRun: data.last_run ? new Date(data.last_run) : null,
    lastSuccess: data.last_success ? new Date(data.last_success) : null,
    lastError: data.last_error ? new Date(data.last_error) : null,
    canRun: data.can_run,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
  };
  return job;
};

export const jsonToHCImage = (data: any) => {
  const image: HCImage = {
    id: data.id,
    projectID: data.project_id,
    hetznerProjectID: data.hetzner_project_id,
    hetznerID: data.hetzner_id,
    type: data.type,
    status: data.status,
    name: data.name,
    description: data.description,
    imageSize: data.image_size,
    diskSize: data.disk_size,
    osFlavor: data.os_flavor,
    osVersion: data.os_version,
    rapidDeploy: data.rapid_deploy,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    deletedAt: data.deleted_at ? new Date(data.deleted_at) : null,
    deprecated: data.deprecated ? new Date(data.deprecated) : null,
    toSnapshot: data.to_snapshot ? new Date(data.to_snapshot) : null,
    billedTill: data.billed_till ? new Date(data.billed_till) : null,
    user: data.user ? jsonToUserBrief(data.user) : null,
  };
  return image;
};

export const jsonToCurrency = (data: any) => {
  return { id: data.id, name: data.name } as Currency;
};

export const jsonToExchangeRate = (data: any) => {
  const rate: ExchangeRate = {
    id: data.id,
    name: data.name,
    description: data.description,
    from: jsonToCurrency(data.from),
    to: jsonToCurrency(data.to),
    default: data.default,
    rate: data.rate,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
  };
  return rate;
};

export const jsonToServiceStats = (data: any) => {
  const stats: ServiceStats = {
    usersCount: data.users_count,
    hcServersCount: data.hc_servers_count,
    hcVolumesCount: data.hc_volumes_count,
    hcVolumesTotalCount: data.total_hc_volumes_count,
    hcVolumesSize: data.hc_volumes_size,
    hcVolumesTotalSize: data.total_hc_volumes_size,
    hcSnapshotsCount: data.hc_snapshots_count,
    hcSnapshotsTotalCount: data.total_hc_snapshots_count,
    hcSnapshotsSize: data.hc_snapshots_size,
    hcSnapshotsTotalSize: data.total_hc_snapshots_size,
    totalWalletsBalance: data.total_wallets_balance,
    totalWalletsDebts: data.total_wallets_debts,
  };
  return stats;
};

export const jsonToFullStats = (data: any) => {
  const stats: FullStats = {
    baseStats: jsonToServiceStats(data.base_stats),
    totalServers: data.total_servers,
    ramActive: data.active_ram,
    ramTotal: data.total_ram,
    diskActive: data.active_disk,
    diskTotal: data.total_disk,
    coresActive: data.active_cores,
    coresTotal: data.total_cores,
    duration: data.duration,
    depositsCount: data.deposits_count,
    depositsTotal: data.total_deposits,
  };
  return stats;
}

export const jsonToUserFullHCServersStats = (data: any) => {
  return {
    activeServers: data.active_servers,
    totalServers: data.total_servers,
    totalDuration: data.total_duration,
    todayCreates: data.today_creates,
  } as UserFullHCServersStats;
}

export const jsonToUserFullServicesStats = (data: any) => {
  return {
    hcServers: jsonToUserFullHCServersStats(data.hc_servers),
    hcVolumes: jsonToUserHCVolumesStats(data.hc_volumes),
    hcSnapshots: jsonToUserHCSnapshotsStats(data.hc_snapshots),
  } as UserFullServicesStats;
}

export const jsonToUserHCVolumesStats = (data: any) => {
  return {
    total: data.total,
    dailyCreates: data.daily_creates,
    totalSize: data.total_size,
  } as UserHCVolumesStats;
}

export const jsonToUserHCSnapshotsStats = (data: any) => {
  return {
    total: data.total,
    dailyCreates: data.daily_creates,
    totalSize: data.total_size,
  } as UserHCSnapshotsStats;
}

export const jsonToTicketAdmin = (data: any) => {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  } as TicketAdmin;
};

export const jsonToTicketUser = (data: any) => {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  } as TicketUser;
};

export const jsonToUserBrief = (data: any) => {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  } as UserBrief;
};

export const jsonToTicketReply = (data: any): TicketReply => {
  const ticketReply: TicketReply = {
    text: data.text,
    admin: data.admin ? jsonToTicketAdmin(data.admin) : undefined,
    createdAt: new Date(data.created_at),
  };
  return ticketReply;
};

export const jsonToTicket = (data: any): Ticket => {
  const department: TicketDepartment = {
    id: data.department.id,
    title: data.department.title,
  };
  const priority: TicketPriority = {
    id: data.priority.id,
    title: data.priority.title,
  };
  const status: TicketStatus = {
    id: data.status.id,
    title: data.status.title,
  };
  const ticket: Ticket = {
    slug: data.slug,
    text: data.text,
    title: data.title,
    department: department,
    priority: priority,
    status: status,
    admin: data.admin ? jsonToTicketAdmin(data.admin) : null,
    user: jsonToUserBrief(data.user),
    closed: data.closed,
    createdAt: new Date(data.created_at),
    replies: data.replies.map((reply: any) => jsonToTicketReply(reply)),
  };
  return ticket;
};

export const jsonToTicketListResponse = (data: any) => {
  const tickets: Ticket[] = data.data.map((t: any) => jsonToTicket(t));
  const res: TicketListResponse = {
    total: data.total,
    count: data.count,
    page: data.page,
    pageSize: data.page_size,
    lastPage: data.last_page,
    data: tickets,
  };
  return res;
};

export const jsonToHCAction = (data: any) => {
  const action: HCAction = {
    id: data.id,
    status: data.status,
    serverId: data.server_id,
    volumeId: data.volume_id,
    imageId: data.image_id,
    descirption: data.description,
    user: data.user ? jsonToUserBrief(data.user) : null,
    type: data.type,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
  };
  return action;
};

export const jsonToPaginatedHCActions = (data: any) => {
  const actions: HCAction[] = data.data.map((a: any) => jsonToHCAction(a));
  const res: PaginatedHCActions = {
    total: data.total,
    count: data.count,
    page: data.page,
    pageSize: data.page_size,
    lastPage: data.last_page,
    data: actions,
  };
  return res;
};

export const jsonToUserEvent = (data: any) => {
  const event: UserEvent = {
    id: data.id,
    note: data.note,
    user: jsonToUserBrief(data.user),
    type: data.type,
    createdAt: new Date(data.created_at),
  };
  return event;
};

export const jsonToPaginatedUserEvents = (data: any) => {
  const events: UserEvent[] = data.data.map((e: any) => jsonToUserEvent(e));
  const res: PaginatedUserEvents = {
    total: data.total,
    count: data.count,
    page: data.page,
    pageSize: data.page_size,
    lastPage: data.last_page,
    data: events,
  };
  return res;
};

export const jsonToHCServerType = (data: any) => {
  const res: HCServerType = {
    id: data.id,
    name: data.name,
    description: data.description,
    cores: data.cores,
    cpuType: data.cpu_type,
    memory: data.memory,
    disk: data.disk,
    storageType: data.storage_type,
  };
  return res;
};

export const jsonToHCLocation = (data: any) => {
  const res: HCLocation = {
    id: data.id,
    name: data.name,
    description: data.description,
    country: data.country,
    city: data.city,
    networkZone: data.network_zone,
  };
  return res;
};

export const jsonToHCServer = (data: any) => {
  const server: HCServer = {
    slug: data.slug,
    projectId: data.project_id,
    projectName: data.project_name,
    name: data.name,
    status: data.status,
    description: data.description,
    ipv4: data.ipv4,
    serverType: jsonToHCServerType(data.server_type),
    location: jsonToHCLocation(data.location),
    user: jsonToUserBrief(data.user),
    createdAt: new Date(data.created_at),
    activeTime: data.active_time,
    diskSize: data.disk_size,
  };
  return server;
};

export const jsonToHCServerPaginatedResult = (data: any) => {
  return {
    count: data.count,
    total: data.total,
    page: data.page,
    pageSize: data.page_size,
    lastPage: data.last_page,
    result: data.result
      ? data.result.map((transaction: any) => jsonToHCServer(transaction))
      : ([] as HCServer[]),
  } as HCServersPaginatedResult;
};
