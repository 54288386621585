import React from "react";
import { numberWithCommas } from "../../utilities/functions";

const yesIcon = <i className="icon-checkmark2 position-static text-success" />;

const noIcon = <i className="icon-cross3 position-static text-danger" />;

const ExchangeRateListTR = (props: { rate: ExchangeRate }) => {

  const { rate } = props;

  return (
    <tr key={"exchange_rate" + rate.id}>
      <td>{rate.id}</td>
      <td>{rate.name}</td>
      <td>{rate.description}</td>
      <td>{rate.from.name}</td>
      <td>{rate.to.name}</td>
      <td>{numberWithCommas(rate.rate)}</td>
      <td className="text-center">{rate.default ? yesIcon : noIcon}</td>
    </tr>
  );
};

export default ExchangeRateListTR;
