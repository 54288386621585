import React from "react";
import { datetimeToString } from "../../utilities/functions";

const HCActionStatusSpan = (status: string) => {
  switch (status) {
    case "success":
      return (
        <span className="badge badge-success" style={{ fontSize: "80%" }}>
          {status.toUpperCase()}
        </span>
      );
    case "failed":
      return (
        <span className="badge badge-danger" style={{ fontSize: "80%" }}>
          {status.toUpperCase()}
        </span>
      );
    default:
      return (
        <span className="badge badge-info" style={{ fontSize: "80%" }}>
          {status.toUpperCase()}
        </span>
      );
  }
};

const HCActionListTR = (props: { action: HCAction }) => {
  const { action } = props;

  return (
    <tr key={"action" + action.id}>
      <td>{action.type.replaceAll("_", " ")}</td>
      <td>{HCActionStatusSpan(action.status)}</td>
      <td>{action.user ? `${action.user.email}` : "-"}</td>
      <td>{datetimeToString(action.createdAt)}</td>
    </tr>
  );
};

export default HCActionListTR;
