import React from "react";
import {useQuery} from "react-query";
import {APIQueryName} from "../../../constants/queries";
import {fetchUserFullStats} from "../../../api/users";
import UserServerStats from "./ServersStats";
import UserSnapshotStats from "./SnapshotStats";
import UserVolumeStats from "./VolumeStats";

const UserFullStats = (props: { user: User }) => {
  const {user} = props;
  const {data, isLoading} = useQuery(
    APIQueryName.USER_FULL_STATS + user.id,
    () => fetchUserFullStats(user.id),
    {keepPreviousData: true, staleTime: Infinity, retry: 2}
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Not found</div>;
  }

  return (
    <>
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Server Stats
        </h6>
      </div>
      <UserServerStats stats={data.hcServers}/>
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Snapshot Stats
        </h6>
      </div>
      <UserSnapshotStats stats={data.hcSnapshots}/>
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Volume Stats
        </h6>
      </div>
      <UserVolumeStats stats={data.hcVolumes}/>
    </>
  );
};

export default UserFullStats;
