import React from "react";
import ImageListTable from "../../components/HCImages/ListTable";
import Content from "../../containers/Content";

const ImageList = () => {
  return (
    <Content>
        <ImageListTable />
    </Content>
  );
};

export default ImageList;
