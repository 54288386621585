import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { runSystemJob, toggleSystemJob } from "../../api/jobs";
import { addNotification } from "../../features/baseSlice";

const yesIcon = <i className="icon-checkmark2 position-static text-success" />;

const noIcon = <i className="icon-cross3 position-static text-danger" />;

const JobListTR = (props: { job: SystemJob }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { job } = props;

  const runMutation = useMutation((id: number) => {
    return runSystemJob(id);
  });

  const toggleJobMutation = useMutation(
    (id: number) => {
      return toggleSystemJob(id);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("jobs", (jobs: any) =>
          jobs.map((oldJob: SystemJob) =>
            oldJob.id === job.id ? { ...job, enabled: !job.enabled } : oldJob
          )
        );
        dispatch(
          addNotification({
            type: "success",
            title: "Job Toggle",
            text: "Job toggled succesfully!",
          })
        );
      },
      onError: () => {
        dispatch(
          addNotification({
            type: "error",
            title: "Job Toggle",
            text: "Failed to toggle job status!",
          })
        );
      },
    }
  );

  useEffect(() => {
    runMutation.isSuccess &&
      dispatch(
        addNotification({
          type: "success",
          title: "Job Run",
          text: "Job run was succesfull!",
        })
      );
    runMutation.isError &&
      dispatch(
        addNotification({
          type: "error",
          title: "Job Run",
          text: "Job run failed!",
        })
      );
  }, [runMutation.isSuccess, runMutation.isError, dispatch]);

  return (
    <tr key={"job" + job.id}>
      <td>{job.id}</td>
      <td>{job.name}</td>
      <td>{job.timeout}</td>
      <td>{job.interval}</td>
      <td className="text-center">{job.enabled ? yesIcon : noIcon}</td>
      <td>
        {!runMutation.isLoading && (
          <button
            type="button"
            className="btn btn-warning btn-sm"
            disabled={runMutation.isLoading ? true : false}
            onClick={() => runMutation.mutate(job.id)}
          >
            Run
          </button>
        )}
        {runMutation.isLoading && (
          <button className="btn btn-warning btn-icon" disabled>
            <i className="icon-spinner6 spinner" />
          </button>
        )}
        {!toggleJobMutation.isLoading && (
          <button
            type="button"
            className="btn btn-info btn-sm ml-1"
            disabled={toggleJobMutation.isLoading ? true : false}
            onClick={() => toggleJobMutation.mutate(job.id)}
          >
            {job.enabled ? "Disable" : "Enable"}
          </button>
        )}
        {toggleJobMutation.isLoading && (
          <button className="btn btn-info btn-icon ml-1" disabled>
            <i className="icon-spinner6 spinner" />
          </button>
        )}
      </td>
    </tr>
  );
};

export default JobListTR;
