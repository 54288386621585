import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import {jsonToFullStats, jsonToServiceStats} from "./mappers";

export const fetchStats = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.STATS.STATS, {
      headers: getDefaultHeaders(getTokenString()),
    });
    return jsonToServiceStats(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchFullStats = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.STATS.FULL_STATS, {
      headers: getDefaultHeaders(getTokenString()),
    });
    return jsonToFullStats(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export default fetchStats;
