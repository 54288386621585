import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchCurrencies } from "../../api/exchnageRates";
import { APIQueryName } from "../../constants/queries";

const CurrencySelect = (props: { fieldName: string }) => {
  const { fieldName } = props;
  const { data } = useQuery(APIQueryName.CURRENCIES, fetchCurrencies, {
    staleTime: 600000,
    retry: 1,
  });

  const [selectedDepartment, setSelectedDepartment] = useState("");

  return (
    <select
      name={fieldName}
      id={fieldName}
      className="form-control"
      value={selectedDepartment}
      onChange={(evt) => setSelectedDepartment(evt.target.value)}
    >
      <option value={0} key={"currency0"}>
        -
      </option>
      {data?.map((currency) => (
        <option value={currency.id} key={"currency" + fieldName + currency.id}>
          {currency.name}
        </option>
      ))}
    </select>
  );
};

export default CurrencySelect;
