import React, { useState } from "react";
import { useQuery } from "react-query";
import fetchHCActions from "../../api/hc";
import { APIQueryName } from "../../constants/queries";
import LoadDataFailed from "../Utils/LoadDataFailed";
import LoadingData from "../Utils/LoadingData";
import SimplePagination from "../Utils/SimplePagination";
import TableEntries from "../Utils/TableEntiries";
import HCActionListTR from "./ActionTR";

const HCActionsListTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const { data, isError, isLoading } = useQuery(
    [APIQueryName.HC_ACTIONS, page, pageSize],
    () => fetchHCActions(page, pageSize),
    { keepPreviousData: true, staleTime: Infinity }
  );

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="card">
      <div className="card-header header-elements-inline">
        <h6 className="card-title">Hetzner Actions</h6>
      </div>
      <div className="dataTables_wrapper no-footer">
        <div className="datatable-header">
          <div className="dataTables_length">
            <label>
              <span>Show:</span>{" "}
              <select
                name="DataTables_Table_0_length"
                className="custom-select"
                onChange={(evt) => setPageSize(parseInt(evt.target.value))}
                defaultValue={pageSize}
              >
                <option key={"pageSize10"} value="10">
                  10
                </option>
                <option key={"pageSize25"} value="25">
                  25
                </option>
                <option key={"pageSize50"} value="50">
                  50
                </option>
                <option key={"pageSize100"} value="100">
                  100
                </option>
              </select>
            </label>
          </div>
        </div>
        <div className="datatable-scroll">
          <table className="table datatable-basic dataTable no-footer">
            <thead>
              <tr>
                <th>Type</th>
                <th>Status</th>
                <th>User</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((action: HCAction) => (
                <HCActionListTR action={action} key={"action" + action.id} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="datatable-footer">
          <TableEntries
            page={page}
            pageSize={pageSize}
            total={data ? data.total : 0}
          />
          <div className="dataTables_paginate paging_simple_numbers">
            <SimplePagination
              totalPage={data ? data.lastPage : 1}
              currentPage={page}
              onPageChange={(page: number) => setPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HCActionsListTable;
