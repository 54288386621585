import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToTicket, jsonToTicketListResponse, jsonToTicketReply } from "./mappers";

export const fetchTickets = async (filter: TicketsFilter) => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.LIST(filter), {
      headers: getDefaultHeaders(getTokenString()),
    });
    return jsonToTicketListResponse(data);
  } catch (error: any) {
    authCheckAndRedirect(error);
    throw error;
  }
};

export const fetchTicket = async (slug: string) => {
  try {
    const { data } = await axios.get(apiRoutes.V1.TICKETING.DETAILS(slug), {
      headers: getDefaultHeaders(getTokenString()),
    });
    const ticket: Ticket = jsonToTicket(data);
    return ticket;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const closeTicket = async (slug: string) => {
  try {
    await axios.patch(
      apiRoutes.V1.TICKETING.CLOSE(slug),
      {},
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const replyTicket = async (req: ReplyTicketRequest) => {
  try {
    const { data } = await axios.post(
      apiRoutes.V1.TICKETING.REPLY(req.slug),
      { text: req.text },
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const res: TicketReply = jsonToTicketReply(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};


export default fetchTickets;
