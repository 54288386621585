import React from "react";

const TableEntries = (props: {
  page: number;
  pageSize: number;
  total: number;
}) => {
  const { page, pageSize, total } = props;
  return (
    <div className="dataTables_info">
      {`Showing ${page * pageSize - pageSize + 1} to ${Math.min(
        page * pageSize,
        total
      )} of ${total} entries`}
    </div>
  );
};

export default TableEntries;
