import React from "react";
import Content from "../containers/Content";
import ServerStats from "../components/Stats/ServerStats";
import {useQuery} from "react-query";
import {APIQueryName} from "../constants/queries";
import {fetchFullStats} from "../api/stats";
import SimpleLoader from "../components/Utils/SimpleLoader";
import VolumeStats from "../components/Stats/VolumeStats";
import SnapshotStats from "../components/Stats/SnapshotStats";
import WalletStats from "../components/Stats/WalletStats";
import ServerResourcesStats from "../components/Stats/ServerResourcesStats";

const FullStats = () => {
  const {data, isLoading} = useQuery(APIQueryName.FULL_STATS, fetchFullStats, {
    staleTime: Infinity,
    retry: 1,
  });

  if (isLoading) {
    return (
      <Content>
        {isLoading && <SimpleLoader/>}
      </Content>
    )
  }

  return (
    <Content>
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Server Stats
        </h6>
      </div>
      {data && <ServerStats stats={data}/>}
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Server Resource Stats
        </h6>
      </div>
      {data && <ServerResourcesStats stats={data}/>}
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Wallet Stats
        </h6>
      </div>
      {data && <WalletStats stats={data}/>}
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Volume Stats
        </h6>
      </div>
      {data && <VolumeStats stats={data}/>}
      <div className="mb-3">
        <h6 className="mb-0 font-weight-semibold">
          Snapshot Stats
        </h6>
      </div>
      {data && <SnapshotStats stats={data}/>}
    </Content>
  );
};

export default FullStats;
