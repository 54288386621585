import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { fetchHCServers } from "../../../api/hcServers";
import { APIQueryName } from "../../../constants/queries";
import SimpleLoader from "../../Utils/SimpleLoader";
import SimplePagination from "../../Utils/SimplePagination";
import TableEntries from "../../Utils/TableEntiries";
import ServersListTableBody from "./ListTableBody";

const ServersListTable = () => {
  const [searchParams, setSearchParams] = useSearchParams ()
  const [queryTimeout, setQueryTimeout] = useState<any>(null);
  const [userIdQueryTimeout, setUserIdQueryTimeout] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [selectedUserId, setSelectedUserId] = useState(parseInt(searchParams.get("userId") || "0"));

  const { data, isLoading } = useQuery(
    [APIQueryName.HC_SERVERS, query, selectedUserId, page, pageSize],
    () => fetchHCServers(page, pageSize, selectedUserId, query),
    {
      staleTime: Infinity,
      retry: 1,
    }
  );

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value;
    queryTimeout && clearTimeout(queryTimeout);
    setQueryTimeout(
      setTimeout(() => {
        setQuery(q);
      }, 500)
    );
  };

  const userIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.currentTarget.value;
    userIdQueryTimeout && clearTimeout(userIdQueryTimeout);
    setUserIdQueryTimeout(
      setTimeout(() => {
        setSelectedUserId(parseInt(id));
        searchParams.set("userId", id)
        setSearchParams(searchParams)
      }, 500)
    );
  };

  useEffect(() => {
    return () => {
      clearTimeout(queryTimeout)
      clearTimeout(userIdQueryTimeout)
    }
  }, [queryTimeout, userIdQueryTimeout]);

  return (
    <div className="card mb-5">
      {isLoading && (
        <tr>
          <td colSpan={5}>
            <p className="text-center">
              <SimpleLoader iconSize={2} />
            </p>
            <p className="text-center">Loading servers ...</p>
          </td>
        </tr>
      )}

      <div
        className="table-responsive table-scrollable"
        style={{ maxHeight: "100%" }}
      >
        <div className="row card-body justify-content-md-center">
          <div className="col-md-4 text-center mt-2">
            <div className="form-group-feedback form-group-feedback-left mb-3 mb-sm-0">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={searchHandler}
              />
              <div className="form-control-feedback">
                <i className="icon-search4 text-muted" />
              </div>
            </div>
          </div>
          <div className="col-md-2 text-center mt-2">
            <div className="form-group-feedback form-group-feedback-left mb-3 mb-sm-0">
              <input
                type="number"
                className="form-control"
                placeholder="User ID"
                defaultValue={selectedUserId}
                onChange={userIdHandler}
              />
              <div className="form-control-feedback">
                <i className="icon-search4 text-muted" />
              </div>
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">Info</th>
              <th className="text-center">User</th>
              <th className="text-center">Active Hours</th>
              <th className="text-center">IPv4</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <ServersListTableBody servers={data?.result} />
        </table>
      </div>
      <div className="card-footer d-sm-flex justify-content-sm-between align-items-sm-center py-sm-2">
        <TableEntries
          page={page}
          pageSize={pageSize}
          total={data ? data.total : 0}
        />

        <SimplePagination
          totalPage={data ? data.lastPage : 1}
          currentPage={page}
          onPageChange={(page: number) => setPage(page)}
        />
      </div>
    </div>
  );
};

export default ServersListTable;
