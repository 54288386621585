export enum APIQueryName {
  USER = "user_",
  USER_FULL_STATS = "user_full_stats_",
  USERS = "users",
  USERS_EVENTS = "users",
  HC_ACTIONS = "hc_actions",
  HC_IMAGES = "hc_images",
  HC_SERVERS = "hc_servers",
  EXCHANGE_RATES = "exchange_rates",
  CURRENCIES = "currencies",
  SERVICE_STATS = "service_stats",
  FULL_STATS = "full_stats",
  TICKETS = "tickets",
  TICKET_DETAILS = "ticket_details_",
}
