import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import routes from "../../constants/routes";
import { toggleSidebar } from "../../features/baseSlice";

const Navbar = () => {
  const navigate = useNavigate()
  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  const dispatch = useDispatch();

  const handleMobileSidebar = () => {
    dispatch(toggleSidebar());
  };

  const logoutHandler = (
    evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    evt.preventDefault();
    localStorage.removeItem("authToken");
    navigate(routes.AUTHENTICATION);
  };

  return (
    <div className="navbar navbar-expand-md navbar-light navbar-static">
      <div className="navbar-header navbar-light d-none d-md-flex align-items-md-center">
        <div className="navbar-brand navbar-brand-md">
          <a href="/" className="d-inline-block">
            <img src="/static/img/logo_light.png" alt="Arvix Cloud" />
          </a>
        </div>
      </div>

      <div className="d-flex flex-1 d-md-none">
        <div className="navbar-brand mr-auto">
          <a href="/" className="d-inline-block">
            <img src="/static/img/logo_light.png" alt="Arvix Cloud" />
          </a>
        </div>

        <button
          className="navbar-toggler collapsed"
          type="button"
          onClick={() => setMobileNavbarOpen(!mobileNavbarOpen)}
        >
          <i className="icon-tree5" />
        </button>

        <button
          className="navbar-toggler sidebar-mobile-main-toggle"
          type="button"
        >
          <i
            className="icon-paragraph-justify3"
            onClick={handleMobileSidebar}
          />
        </button>
      </div>

      <div
        className={`collapse navbar-collapse ${mobileNavbarOpen ? "show" : ""}`}
        id="navbar-mobile"
      >
        <span className="my-3 my-md-0 ml-md-3 mr-md-auto"> </span>

        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              href="#signout"
              className="navbar-nav-link caret-0"
              onClick={logoutHandler}
            >
              <i className="icon-switch2" />
              <span className="d-md-none ml-2">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
