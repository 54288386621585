import React from "react";
import UserEmailVerification from "./EmailVerification";
import UserPhoneVerification from "./PhoneVerification";

const UserInfo = (props: {user: User}) => {
  const { user } = props;
  return (
    <div className="row">
      <div className="col-md-6">
        <UserPhoneVerification user={user} />
      </div>
      <div className="col-md-6">
        <UserEmailVerification user={user} />
      </div>
    </div>
  );
};

export default UserInfo;
