import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import routes from "../../constants/routes";
import { baseState } from "../../features/baseSlice";

const Sidebar = () => {
  const { pathname } = useLocation();

  const { mobileSidebarOpen } = useSelector(baseState);

  return (
    <div
      className={`sidebar sidebar-light sidebar-main sidebar-expand-lg ${
        mobileSidebarOpen ? "sidebar-mobile-expanded" : ""
      }`}
    >
      <div className="sidebar-content">
        <div className="sidebar-section">
          <ul className="nav nav-sidebar" data-nav-type="accordion">
            <li className="nav-item-header">
              <div className="text-uppercase font-size-xs line-height-xs">
                Main Menu
              </div>{" "}
              <i className="icon-menu" title="Main" />
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link${
                  pathname === routes.HOME ? " active" : ""
                }`}
                to={routes.HOME}
              >
                <i className="icon-home4" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.USERS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.USERS}>
                <i className="icon-user" /> <span>Users</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.USERS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.USERS}
                    className={`nav-link${
                      pathname === routes.USERS ? " active" : ""
                    }`}
                  >
                    User List
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.TICKETS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.TICKETS}>
                <i className="icon-comment-discussion" /> <span>Tickets</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.TICKETS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.TICKETS}
                    className={`nav-link${
                      pathname === routes.TICKETS ? " active" : ""
                    }`}
                  >
                    Ticket List
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.EXCHANGE_RATES) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.EXCHANGE_RATES}>
                <i className="icon-coin-euro" /> <span>Exchange Rates</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.EXCHANGE_RATES) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.EXCHANGE_RATES}
                    className={`nav-link${
                      pathname === routes.EXCHANGE_RATES ? " active" : ""
                    }`}
                  >
                    List
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={routes.EXCHANGE_RATE_CREATE}
                    className={`nav-link${
                      pathname === routes.EXCHANGE_RATE_CREATE ? " active" : ""
                    }`}
                  >
                    Create
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.HC_SERVERS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.HC_SERVERS}>
                <i className="icon-server" /> <span>HC Servers</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.HC_SERVERS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.HC_SERVERS}
                    className={`nav-link${
                      pathname === routes.HC_SERVERS ? " active" : ""
                    }`}
                  >
                    Servers List
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.HC_IMAGES) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.HC_IMAGES}>
                <i className="icon-camera" /> <span>HC Images</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.HC_IMAGES) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.HC_IMAGES}
                    className={`nav-link${
                      pathname === routes.HC_IMAGES ? " active" : ""
                    }`}
                  >
                    Image List
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.SYSTEM) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.JOBS}>
                <i className="icon-wrench3" /> <span>System</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.SYSTEM) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.JOBS}
                    className={`nav-link${
                      pathname === routes.JOBS ? " active" : ""
                    }`}
                  >
                    Jobs
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item nav-item-submenu${
                pathname.includes(routes.STATS) ? " nav-item-open" : ""
              }`}
            >
              <Link className="nav-link" to={routes.FULL_STATS}>
                <i className="icon-stats-dots" /> <span>Stats</span>
              </Link>

              <ul
                className="nav nav-group-sub"
                style={{
                  display: pathname.includes(routes.STATS) ? "block" : "none",
                }}
              >
                <li className="nav-item">
                  <Link
                    to={routes.FULL_STATS}
                    className={`nav-link${
                      pathname === routes.FULL_STATS ? " active" : ""
                    }`}
                  >
                    Full Stats
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link${
                  pathname === routes.SSH_KEYS ? " active" : ""
                }`}
                to={routes.SSH_KEYS}
              >
                <i className="icon-key" />
                <span>کلید های SSH</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
