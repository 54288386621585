import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import apiRoutes from "../constants/apiRoutes";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToCurrency, jsonToExchangeRate } from "./mappers";

export const fetchExchangeRates = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.EXCHANGE_RATES.LIST, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const rates: ExchangeRate[] = data.map((rate: any) =>
      jsonToExchangeRate(rate)
    );
    return rates;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchCurrencies = async () => {
  try {
    const { data } = await axios.get(apiRoutes.V1.EXCHANGE_RATES.CURRENCIES, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const currencies: Currency[] = data.map((currency: any) =>
      jsonToCurrency(currency)
    );
    return currencies;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const createExchangeRate = async (req: ExchangeRateCreateRequest) => {
  try {

    const { data } = await axios.post(apiRoutes.V1.EXCHANGE_RATES.CREATE, req, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: ExchangeRate = jsonToExchangeRate(data);
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const useCreateExchangeRate = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: ExchangeRateCreateRequest) => {
      return createExchangeRate(req);
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData(APIQueryName.EXCHANGE_RATES, (rates: any) => {
          // No list to add to
          if (!rates) {
            return;
          }
          return [result, ...rates];
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Create Exchange Rate",
            text: "Exchange rate created!",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Create Exchange Rate",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};
