import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import {jsonToPaginatedUserEvents, jsonToUser, jsonToUserFullServicesStats, jsonToUserListResponse} from "./mappers";

export const fetchUsers = async (
  page: number,
  pageSize: number,
  search: string
) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.USERS.LIST(page, pageSize, search),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToUserListResponse(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export default fetchUsers;

export const fetchUser = async (
  userId: number,
) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.USERS.GET(userId),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToUser(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchUserFullStats = async (userId: number) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.USERS.FULL_STATS(userId),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToUserFullServicesStats(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const fetchUserEvents = async (page: number, pageSize: number) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.USERS.EVENTS(page, pageSize),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    return jsonToPaginatedUserEvents(data);
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const addCredit = async (req: UserAddCreditRequest) => {
  try {
    await axios.patch(apiRoutes.V1.USERS.ADD_CREDIT(req.user_id), req, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const verifyUserPhone = async (userId: number) => {
  try {
    await axios.patch(apiRoutes.V1.USERS.VERIFY_PHONE(userId), {}, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

export const verifyUserEmail = async (userId: number) => {
  try {
    await axios.patch(apiRoutes.V1.USERS.VERIFY_EMAIL(userId), {}, {
      headers: getDefaultHeaders(getTokenString()),
    });
    const res: SimpleResponse = { status: "OK" };
    return res;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};

