import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { closeTicket, replyTicket } from "../api/tickets";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";


export const useCloseTicket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (slug: string) => {
      return closeTicket(slug);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.TICKET_DETAILS + variables,
          (ticket: any) => {
            ticket.closed = true;
            return ticket;
          }
        );
        queryClient.setQueryData(APIQueryName.TICKETS, (tickets: any) => {
          // No list to add to
          if (!tickets) {
            return;
          }

          // Won't work, tickets are paginated.
          return tickets.map((ticket: Ticket) => {
            if (ticket.slug === variables) {
              ticket.closed = true;
              return ticket
            }
            return ticket;
          });
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Close Ticket",
            text: "Ticket closed.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Close Ticket",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};


export const useReplyTicket = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (req: ReplyTicketRequest) => {
      return replyTicket(req);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.TICKET_DETAILS + variables.slug,
          (ticket: any) => {
            ticket.replies = [...ticket.replies, result];
            return ticket;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "Ticket Reply",
            text: "Ticket reply added.",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Ticket Reply",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};
