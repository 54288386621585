import React from "react";
import { Link } from "react-router-dom";
import routes from "../../constants/routes";
import TicketRowPrioritySpan from "./TicketRowPrioritySpan";
import TicketRowStatusSpan from "./TicketRowStatusSpan";

const TicketRow = (props: { ticket: Ticket }) => {
  const {ticket} = props;
  return (
    <tr>
      <td className="text-center">
        <Link to={{ pathname: `${routes.TICKET_DETAILS}${ticket.slug}` }}>
          <span className="font-weight-bold text-default">
            {" "}
            {ticket.title}{" "}
          </span>
        </Link>
      </td>
      <td className="text-center">
        {" "}
        <span className="badge badge-dark border-white text-white" style={{fontSize: "80%"}}>
          {ticket.department.title}
        </span>{" "}
      </td>
      <td className="text-center">
        {" "}
        <TicketRowPrioritySpan priority={ticket.priority} />{" "}
      </td>

      <td className="text-center">
        <p className="mb-0 font-weight-semibold text-center ">
          {" "}
          <TicketRowStatusSpan status={ticket.status} />{" "}
        </p>
      </td>
      <td className="text-center">
          <span className="font-weight-bold text-default">
            {" "}
            {`${ticket.user.firstName} ${ticket.user.lastName} (${ticket.user.email})`}
          </span>
      </td>
    </tr>
  );
};

export default TicketRow;
