import React from "react";
import TicketingListTable from "../../components/Ticketing/ListTable";
import Content from "../../containers/Content";

const TicketList = () => {
  return (
    <Content>
        <TicketingListTable />
    </Content>
  );
};

export default TicketList;
