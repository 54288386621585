import React from "react";
import JobsListTable from "../../components/SystemJobs/ListTable";
import Content from "../../containers/Content";

const JobList = () => {
  return (
    <Content>
        <JobsListTable />
    </Content>
  );
};

export default JobList;
