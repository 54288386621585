/* eslint-disable import/no-cycle */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import baseReducer from "./features/baseSlice";
import userReducer from "./features/users/userSlice";

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    base: baseReducer,
    user: userReducer,
  });
}
