import React from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../../../constants/routes";

const UserDetailsTabs = (props: {activeTab: string, userId: number}) => {
  const { pathname } = useLocation();
  const { activeTab, userId } = props;

  return (
    <ul className="nav nav-tabs nav-tabs-bottom flex-nowrap mb-0">
      <Link
        to={`${routes.USERS}/${userId}/info`}
        className={activeTab === "info" ? "nav-link active" : "nav-link"}
      >
        <li className="nav-item">
          <i className="icon-menu7 mr-1 ml-1" /> Info
        </li>
      </Link>
      <Link
        to={`${routes.USERS}/${userId}/add-credit`}
        className={
          pathname.includes("add-credit") ? "nav-link active" : "nav-link"
        }
      >
        <li className="nav-item">
          <i className="icon-menu7 mr-1 ml-1" /> Add Credit
        </li>
      </Link>
      <Link
        to={`${routes.USERS}/${userId}/full-stats`}
        className={
          pathname.includes("full-stats") ? "nav-link active" : "nav-link"
        }
      >
        <li className="nav-item">
          <i className="icon-stats-dots mr-1 ml-1" /> Stats
        </li>
      </Link>
    </ul>
  );
};

export default UserDetailsTabs;
