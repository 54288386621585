import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { addCredit, verifyUserEmail, verifyUserPhone } from "../api/users";
import { APIQueryName } from "../constants/queries";
import { addNotification } from "../features/baseSlice";

export const useUserAddCredit = () => {
  const dispatch = useDispatch();

  return useMutation(
    (req: UserAddCreditRequest) => {
      return addCredit(req);
    },
    {
      onSuccess: (result) => {
        dispatch(
          addNotification({
            type: "success",
            title: "Add Credit",
            text: "Credit added for user!",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Add Credit",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};

export const useUserVerifyPhone = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (userId: number) => {
      return verifyUserPhone(userId);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.USER + variables,
          (user: any) => {
            if (!user) {
              return
            }
            user.phoneVerifiedAt = new Date();
            return user;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "User Phone Verification",
            text: "User phone verified!",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "User Phone Verification",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};

export const useUserVerifyEmail = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    (userId: number) => {
      return verifyUserEmail(userId);
    },
    {
      onSuccess: (result, variables, context) => {
        queryClient.setQueryData(
          APIQueryName.USER + variables,
          (user: any) => {
            if (!user) {
              return
            }
            user.emailVerifiedAt = new Date();
            return user;
          }
        );
        dispatch(
          addNotification({
            type: "success",
            title: "User Email Verification",
            text: "User email verified!",
          })
        );
      },
      onError: (error: any, variables, context) => {
        if (error.response && error.response.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "User Email Verification",
              text: error.response.data.message,
            })
          );
        }
      },
    }
  );
};
