import React from "react";
import {numberWithCommas} from "../../utilities/functions";

const SnapshotStats = (props: {stats: FullStats}) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-camera icon-3x text-grey-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcSnapshotsTotalCount)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Snapshots</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-camera icon-3x text-grey-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcSnapshotsTotalSize)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Snapshots Size (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-camera icon-3x text-pink-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcSnapshotsCount)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Snapshots</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-camera icon-3x text-pink-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.baseStats.hcSnapshotsSize)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Active Snapshots Size</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapshotStats;
