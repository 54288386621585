import axios from "axios";
import apiRoutes from "../constants/apiRoutes";
import getDefaultHeaders from "../features/utils";
import { authCheckAndRedirect } from "../utilities/functions";
import { getTokenString } from "./auth";
import { jsonToHCServerPaginatedResult } from "./mappers";

export const fetchHCServers = async (
  page: number,
  pageSize: number,
  userId?: number,
  query?: string,
  deleted?: boolean
) => {
  try {
    const { data } = await axios.get(
      apiRoutes.V1.HCSERVERS.LIST(page, pageSize, userId, query, deleted),
      {
        headers: getDefaultHeaders(getTokenString()),
      }
    );
    const result: HCServersPaginatedResult =
      jsonToHCServerPaginatedResult(data);
    return result;
  } catch (error: any) {
    authCheckAndRedirect(error.response);
    throw error;
  }
};
