import { apiAddress } from "../settings";

const apiRoutes = {
  V1: {
    TOKEN_VALIDATION: apiAddress + "/v1/auth/validations/token/check/",
    EMAIL_VALIDATION: apiAddress + "/v1/auth/validations/email",
    Phone_VALIDATION: apiAddress + "/v1/auth/validations/phone",
    REGISTER: apiAddress + "/v1/auth/register",
    LOGIN: apiAddress + "/v1/auth/login",
    USERS: {
      GET: (userId: number) => {
        return `${apiAddress}/v1/users/${userId}`;
      },
      LIST: (page: number, pageSize: number, search: string) => {
        return `${apiAddress}/v1/users?page=${page}&page_size=${pageSize}&q=${search}`;
      },
      EVENTS: (page: number, pageSize: number) => {
        return `${apiAddress}/v1/users/events?page=${page}&page_size=${pageSize}`;
      },
      ADD_CREDIT: (userId: number) => {
        return `${apiAddress}/v1/user/${userId}/add-credit`;
      },
      VERIFY_PHONE: (userId: number) => {
        return `${apiAddress}/v1/user/${userId}/verify-phone`;
      },
      VERIFY_EMAIL: (userId: number) => {
        return `${apiAddress}/v1/user/${userId}/verify-email`;
      },
      FULL_STATS: (userId: number) => {
        return `${apiAddress}/v1/user/${userId}/full-stats`;
      },
    },
    SYSTEM: {
      JOBS: {
        LIST: apiAddress + "/v1/jobs",
        RUN: (id: number) => {
          return `${apiAddress}/v1/jobs/${id}`;
        },
        TOGGLE: (id: number) => {
          return `${apiAddress}/v1/jobs/${id}/toggle`;
        },
      },
    },
    EXCHANGE_RATES: {
      LIST: apiAddress + "/v1/exchangerates",
      CREATE: apiAddress + "/v1/exchangerates",
      CURRENCIES: apiAddress + "/v1/exchangerates/currencies",
    },
    STATS: {
      STATS: apiAddress + "/v1/stats",
      FULL_STATS: apiAddress + "/v1/stats/full",
    },
    HC: {
      ACTIONS: {
        LIST: (page: number, pageSize: number) => {
          return `${apiAddress}/v1/hc/actions?page=${page}&page_size=${pageSize}`;
        },
      },
    },
    USER: {
      PROFILE: apiAddress + "/v1/user",
      SEND_EMAIL_VERIFICATION: apiAddress + "/v1/user/email/verification",
      SEND_PHONE_VERIFICATION: apiAddress + "/v1/user/phone/verification",
      VRIFY_PHONE_CODE: apiAddress + "/v1/user/phone/verification",
      VRIFY_EMAIL: apiAddress + "/v1/user/email/verification",
      UPDATE_PROFILE: apiAddress + "/v1/user",
      PASSWORD_CHANGE: apiAddress + "/v1/user/password",
      FORGET_PASSWORD: apiAddress + "/v1/auth/reset",
      RESET_PASSWORD: apiAddress + "/v1/auth/reset",
      PASSWORD_RESET_KEY_VALIDATION: (key: string) => {
        return `${apiAddress}/v1/auth/reset?key=${key}`;
      },
    },
    TICKETING: {
      DETAILS: (slug: string) => {
        return apiAddress + "/v1/ticket/" + slug;
      },
      CLOSE: (slug: string) => {
        return apiAddress + "/v1/ticket/" + slug + "/close";
      },
      REPLY: (slug: string) => {
        return apiAddress + "/v1/ticket/" + slug + "/reply";
      },
      CREATE: apiAddress + "/v1/ticket",
      LIST: (filter: TicketsFilter) => {
        let url = `${apiAddress}/v1/tickets?page=${filter.page}&page_size=${filter.pageSize}&q=${filter.query}&`;
        if (filter.closed !== undefined) {
          url += `closed=${filter.closed}&`;
        }
        if (filter.userId) {
          url += `user_id=${filter.userId}&`;
        }
        if (filter.departments) {
          filter.departments.forEach((department) => {
            url += `department=${department}&`;
          });
        }
        if (filter.priorities) {
          filter.priorities.forEach((priority) => {
            url += `priority=${priority}&`;
          });
        }
        if (filter.statuses) {
          filter.statuses.forEach((status) => {
            url += `status=${status}&`;
          });
        }
        return url;
      },
      STATS: apiAddress + "/v1/ticket/stats",
      DEPARTMENTS_LIST: apiAddress + "/v1/ticket/departments",
      PRIORITIES_LIST: apiAddress + "/v1/ticket/priorities",
    },
    WALLET: {
      SUMMARY: apiAddress + "/v1/wallet",
    },
    PAYMENT: {
      INITIATE: apiAddress + "/v1/payment/initiate",
      CONFIRM: (key: string) => {
        return `${apiAddress}/v1/payment/confirm/${key}`;
      },
    },
    HCIMAGES: {
      LIST: `${apiAddress}/v1/hc/images`,
    },
    HCSERVERS: {
      LIST: (
        page: number,
        pageSize: number,
        userId?: number,
        query?: string,
        deleted?: boolean
      ) => {
        let url = `${apiAddress}/v1/hc/servers?page=${page}&page_size=${pageSize}`;
        if (userId) {
          url += `&user_id=${userId}`;
        }
        if (query) {
          url += `&q=${query}`;
        }
        if (deleted) {
          url += `&deleted=true`;
        }
        return url;
      },
    },
  },
  V2: {},
};

export default apiRoutes;
