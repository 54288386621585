import React from "react";
import UserListTable from "../../components/Users/ListTable";
import Content from "../../containers/Content";

const UserList = () => {
  return (
    <Content>
        <UserListTable />
    </Content>
  );
};

export default UserList;
