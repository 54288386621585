import React from "react";
import {numberWithCommas} from "../../../utilities/functions";

const UserVolumeStats = (props: {stats: UserHCVolumesStats}) => {
  const {stats} = props;
  return (
    <div className="row">
      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-add icon-3x text-slate-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.total}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-database-add icon-3x text-slate-600"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{numberWithCommas(stats.totalSize)}</h3>
              <span className="text-uppercase font-size-sm text-muted">Total Size (GB)</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-6 col-xl-3">
        <div className="card card-body">
          <div className="media">
            <div className="mr-3 align-self-center">
              <i className="icon-plus2 icon-3x text-orange-300"></i>
            </div>

            <div className="media-body text-right">
              <h3 className="font-weight-semibold mb-0">{stats.dailyCreates}</h3>
              <span className="text-uppercase font-size-sm text-muted">Today Creates</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserVolumeStats;
