import React from "react";

const LoadDataFailed = () => {
  return (
    <div className="text-center mb-5 mt-2">
        <i className={`icon-warning icon-5x text-warning`} />
        <p className="mt-3">Failed to load data!</p>
      </div>
  );
};
export default LoadDataFailed;
