import React from "react";
import { QueryClient, useQuery } from "react-query";
import fetchSystemJobs, { toggleSystemJob } from "../../api/jobs";
import LoadDataFailed from "../Utils/LoadDataFailed";
import LoadingData from "../Utils/LoadingData";
import JobListTR from "./JobTR";

const JobsListTable = () => {
  const queryClient = new QueryClient();
  queryClient.setMutationDefaults("toggleSystemJob", {
    mutationFn: toggleSystemJob,
    onSuccess: (result, variables, context) => {
      console.log("asdasdasdasdasd");
      // Replace optimistic todo in the todos list with the result
      // queryClient.setQueryData('todos', old => old.map(todo => todo.id === context.optimisticTodo.id ? result : todo))
    },
    onError: (error, variables, context) => {
      // Remove optimistic todo from the todos list
      // queryClient.setQueryData('todos', old => old.filter(todo => todo.id !== context.optimisticTodo.id))
    },
  });

  const { data, isError, isLoading } = useQuery("jobs", fetchSystemJobs, {
    keepPreviousData: true,
    staleTime: 10000,
  });

  if (isLoading) {
    return <LoadingData />;
  }

  if (isError) {
    return <LoadDataFailed />;
  }

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Timeout</th>
              <th>Interval</th>
              <th>Enabled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((job) => (
              <JobListTR job={job} key={"job" + job.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobsListTable;
