import React from "react";
import { numberWithCommas } from "../../../utilities/functions";

const yesIcon = <i className="icon-checkmark2 position-static text-success" />;

const noIcon = <i className="icon-cross3 position-static text-danger" />;

const UserDetailsInfoBar = (props: {user: User}) => {
  const {user} = props;

  return (
    <div className="card">
      <div className="card-header header-elements-sm-inline">
        <h3 className="card-title">
          {`${user.firstName} ${user.lastName} (${user.email})`}
        </h3>
        <div className="header-elements">
          <ul className="list-inline mb-0">
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>Phone Verified: </strong>
              {!!user.phoneVerifiedAt ? yesIcon : noIcon}
            </li>
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>Email Verified: </strong>
              {!!user.emailVerifiedAt ? yesIcon : noIcon}
            </li>
            <li className="list-inline-item" style={{ direction: "ltr" }}>
              <strong>Balance: </strong>
              {numberWithCommas(user.balance)}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsInfoBar;
